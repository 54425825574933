<template>
  <t-modal
    :name="id"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    ref="popup"
  >
    <!-- @see exportToPDF() -->
    <div :ref="`document_${code}`" :key="`document_${code}`">
      <div style="height: 160px;" class="flex justify-center items-center">
        <VueQRCode :value="code" :tag="`img`" :options="{ width: 160 }" />
      </div>
      <div class="font-bold text-center">{{ code }}</div>
    </div>

    <div class="flex justify-center items-center mb-6">
      <button
        class="flex gap-2 justify-center items-center py-2 px-3 rounded hover:bg-gray-100"
        @click="exportToPNG"
      >
        <img src="@/assets/placeholder/download.svg" />
        <span>Download as PNG</span>
      </button>
    </div>
  </t-modal>
</template>

<script>
import html2pdf from 'html2pdf.js'

import VueQRCode from '@chenfengyuan/vue-qrcode'
import { useDownloader } from '@/composables'

export default {
  name: 'DownloadQRCode',

  components: {
    VueQRCode,
  },

  props: {
    id: {
      type: [String, Number],
      requred: true,
    },

    code: {
      type: [Number, String],
      required: true,
    },
  },

  methods: {
    async exportToPDF() {
      // the margin-bottom / blank-space is important,
      // the html2canvas lib probably requires a min height
      const html = document.createElement('div')
      html.appendChild(this.$refs[`document_${this.code}`].cloneNode(true))
      html.appendChild(document.createElement('br'))
      html.appendChild(document.createElement('br'))
      html.appendChild(document.createElement('br'))
      html.appendChild(document.createElement('br'))
      html.appendChild(document.createElement('br'))

      console.log('html & code', html, this.code)

      if (html) {
        await html2pdf()
          .set({
            margin: [0, 0, 0, -0.2],
            filename: `vehicle-qrcode-${this.code}.pdf`,
            image: { type: 'jpeg', quality: 0.9 },
            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
          })
          .from(html)
          .save()
      }
    },

    async exportToPNG() {
      const ref = this.$refs[`document_${this.code}`]
      const img = ref.querySelector('img')

      if (img) {
        useDownloader(img.src, `vehicle-qrcode-${this.code}.png`, 'image/png')
      }
    },
  },
}
</script>

<style lang="scss"></style>
