<template>
  <section>
    <VehicleAddEdit
      :es-id="addEdit.esId"
      :stepper-mode="addEdit.stepperMode"
      :stepper-step="addEdit.stepperStep"
      :primary-key="addEdit.primaryKey"
      :primary-key-lock="addEdit.primaryKeyLock"
      :raw-data="addEdit.rawData"
      :busy="addEdit.busy"
      @refresh="$store.dispatch('fsTable/fetchData')"
    />

    <google-map-modal-alt
      :marker-is-updatable="false"
      :variant="`vehicle`"
      :title="`Vehicle On The Map`"
      :subtitle="getMapVehicleSubtitle"
    />

    <content-section :spacing="false">
      <div
        class="flex flex-wrap items-center justify-between px-6 mb-4 content-spacing"
      >
        <div class="flex items-center">
          <oto-page-title :title="pageTitle" />

          <div class="block" v-if="$acl.canCreate('vehicles')">
            <div @click="toggleDropdown" class="tooggle-btn">
              <img src="@/assets/img/multiple-btn.png" width="55" height="30" />
            </div>

            <div
              v-bind:class="{
                hidden: !dropdownPopoverShow,
                block: dropdownPopoverShow,
              }"
              class="absolute float-left py-2 mt-1 text-base text-left list-none bg-white rounded shadow-2xl z-1"
              style="min-width:12rem"
              ref="popoverDropdownRef"
            >
              <div class="cursor-pointer item-btn" @click="add('single')">
                Add single vehicle
              </div>
              <div class="cursor-pointer item-btn" @click="add('multiple')">
                Add multiple vehicles
              </div>
            </div>
          </div>
        </div>

        <!-- <date-range-picker
          @apply-date="handleApplyDate($event)"
          @cancel-date="handleCancelDate($event)"
        /> -->
      </div>

      <div
        class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row px-5"
      >
        <SummaryCard
          title="Total Vehicles"
          :value="indexMetaData.summary.total"
          variant="gray"
        />
        <!-- <SummaryCard
          title="On Ride"
          :value="indexMetaData.summary.on_ride"
          variant="light-blue"
        />
        <SummaryCard
          title="Idle"
          :value="indexMetaData.summary.idle"
          variant="orange"
        /> -->
      </div>
      <template>
        <FSTable
          :fst-id="fstId"
          :endpoint="endpoint"
          :qso="qso"
          :headers="getTableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="fstId"
                :slot-width="slotWidth"
                :options="getFilteredItems"
              />
            </keep-alive>
          </template>

          <template v-slot:default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" text-fallback-always>
                  <FSTableRowItem :text="item.name" />

                  <FSTableRowItem>
                    <battery-status :level="item.lock.power_level" />
                  </FSTableRowItem>

                  <FSTableRowItem :text="item.lock.lock_id" />

                  <FSTableRowItem :text="item.qr_code" />

                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles' ? false : true"
                  >
                    <span
                      v-text="
                        getUTCAwareTime(item.last_connected_at, {
                          relative: true,
                        })
                      "
                      :title="getUTCAwareTime(item.last_connected_at)"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles' ? false : true"
                  >
                    <span
                      v-text="
                        getUTCAwareTime(item.last_loc_updated_at, {
                          relative: true,
                        })
                      "
                      :title="getUTCAwareTime(item.last_loc_updated_at)"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <template v-if="getEnabledFlags(item).length > 0">
                      <div class="flex items-center">
                        <template
                          v-for="(flag, flagIndex) in getEnabledFlags(item)"
                        >
                          <vehicle-flag-icon
                            v-tooltip.bottom="getFlagAlias(flag, item)"
                            size="sm"
                            class="mr-1"
                            :name="flag"
                            :key="`vehicle-${itemIndex}-flag-${flagIndex}`"
                          />
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      No Flags
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles' ? false : true"
                  >
                    <HeartbeatStatus
                      :status="item.heart_beat"
                      :is-available="item.is_available"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div
                      class="vehicle-map-icon"
                      @click="showGoogleMapModal(item)"
                    >
                      <i class="fas fa-map" />
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles'"
                    :text="item.owner ? item.owner.email : '--'"
                  />
                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles'"
                    :text="item.owner ? item.owner.full_name : '--'"
                  />

                  <!-- <FSTableRowItem>
                    <div class="flex items-center">
                      <MoreActions
                        :data="item"
                        :vehicle-type="`scooter`"
                        :current-status="item.lock.is_locked"
                        :redirectPath="path"
                        @edit="onOpenES({ primaryKey: $event.vehicleId })"
                        @change="item.lock.is_locked = $event"
                        @force-sync="$store.dispatch('fsTable/fetchData')"
                        @delete:failed="onDeleteFailed"
                        @delete:succeeded="onDeleteSucceeded"
                      />
                    </div>
                  </FSTableRowItem> -->
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem :text="item.name" />

                  <FSTableRowItem :text="item.lock.lock_id" />

                  <!-- <FSTableRowItem>
                    <div class="flex items-center">
                      <MoreActions
                        :data="item"
                        :vehicle-type="`scooter`"
                        :current-status="item.lock.is_locked"
                        :redirectPath="path"
                        @edit="onOpenES({ primaryKey: $event.vehicleId })"
                        @change="item.lock.is_locked = $event"
                        @force-sync="$store.dispatch('fsTable/fetchData')"
                        @delete:failed="onDeleteFailed"
                        @delete:succeeded="onDeleteSucceeded"
                      />
                    </div>
                  </FSTableRowItem> -->
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Battery</div>
                      <div class="col-span-5 right-text">
                        <battery-status :level="item.lock.power_level" />
                      </div>
                    </div>

                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">QRCode</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          {{ item.qr_code || `--` }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="fstId === 'marketplace-vehicles' ? false : true"
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Last Connected</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <span
                            v-text="
                              getUTCAwareTime(item.last_connected_at, {
                                relative: true,
                              })
                            "
                            :title="getUTCAwareTime(item.last_connected_at)"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="fstId === 'marketplace-vehicles' ? false : true"
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Last Located</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <span
                            v-text="
                              getUTCAwareTime(item.last_loc_updated_at, {
                                relative: true,
                              })
                            "
                            :title="getUTCAwareTime(item.last_loc_updated_at)"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Flags</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <template v-if="getEnabledFlags(item).length > 0">
                            <div class="flex items-center">
                              <template
                                v-for="(flag, flagIndex) in getEnabledFlags(
                                  item
                                )"
                              >
                                <vehicle-flag-icon
                                  v-tooltip.bottom="getFlagAlias(flag, item)"
                                  size="sm"
                                  class="mr-1"
                                  :name="flag"
                                  :key="
                                    `vehicle-${itemIndex}-flag-${flagIndex}`
                                  "
                                />
                              </template>
                            </div>
                          </template>
                          <template v-else>
                            No Flags
                          </template>
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Heartbeat</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <HeartbeatStatus
                            :status="item.heart_beat"
                            :is-available="item.is_available"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Last Location</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <div
                            class="vehicle-map-icon"
                            @click="showGoogleMapModal(item)"
                          >
                            <i class="fas fa-map" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="fstId === 'marketplace-vehicles'"
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Owner Email</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          {{ item.owner ? item.owner.email : '--' }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="fstId === 'marketplace-vehicles'"
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Assign To</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          {{ item.owner ? item.owner.full_name : '--' }}
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import { EventBus, getUTCAwareTime } from '@/utils'

import VehicleAddEdit from '@/composites/vehicle/add-edit/VehicleAddEdit.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'

export default {
  name: 'VehicleIndex',

  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    fstId: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
    qso: {
      type: Object,
      required: true,
      default: () => ({ append: '', prepend: '?' }),
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    OtoPageTitle: () => import('@/components/ui/OtoPageTitle'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    BatteryStatus: () => import('@/components/badge/BatteryStatus'),
    HeartbeatStatus: () => import('@/components/badge/HeartbeatStatus'),
    VehicleFlagIcon: () => import('@/components/badge/VehicleFlagIcon'),
    GoogleMapModalAlt: () => import('@/components/modals/GoogleMapModalAlt'),
    // MoreActions: () => import('../shared/MoreActions.vue'),

    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,

    VehicleAddEdit,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      fallbackText: '--',
      path: '',
      indexMetaData: {
        summary: {
          total: 0,
          on_ride: 0,
          idle: 0,
        },
        count: {
          total: 0,
        },
      },
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'Name', width: '35%', sort: null },
        { text: 'IMEI', width: '35%', sort: null },
        // { text: 'Actions', width: '20%', sort: null },
      ],
      opened: [],
      dropdownPopoverShow: false,

      mapVehicleData: {},
      mapModalState: false,
      mapModalLoc: {
        // lat: "90.2119",
        // lng: "27.21"
      },

      // generated by generateFilterOptions()
      filterOptions: [],

      // todo: export from another file & import it
      addEdit: {
        // EdgeStack component
        esId: 'vehicle-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
      },
    }
  },
  mounted() {
    EventBus.$on('force-refresh', () => {
      this.$store.dispatch('fsTable/fetchData')
    })
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getMapVehicleSubtitle() {
      if (this.mapVehicleData && this.mapVehicleData.last_connected_at) {
        return (
          'Last Location Updated On: ' +
          dayjs(this.mapVehicleData.last_connected_at.split('.')[0]).format(
            'DD MMM, YYYY hh:mm:ss a'
          )
        )
      }
      return 'Last Location Updated On: --'
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        return this.filterItems
      } else {
        return this.filterOptions
      }
    },
  },

  async created() {
    await this.generateFilterOptions()
    this.path = this.$route.path
  },

  methods: {
    getUTCAwareTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },

    // todo: export from another file & import it
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.addEdit.busy = true
        this.$edgeStack.open(this.addEdit.esId)

        const vReq = this.$http.get(useEndpoints.vehicle.details(primaryKey))

        const requests = [vReq]

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              let data = {}
              const [vRes] = responses
              console.log(vRes.data, 'vRes')

              data = { ...vRes.data }

              this.addEdit.stepperMode = 'free'

              this.addEdit.busy = false
              this.addEdit.rawData = data
              this.addEdit.primaryKey = primaryKey // for vehicle
              this.addEdit.primaryKeyLock = data.lock.id // for lock
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.addEdit.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.addEdit.stepperMode = 'strict'

      this.addEdit.busy = false
      this.addEdit.rawData = null // for vehicle
      this.addEdit.primaryKey = null // for vehicle
      this.addEdit.primaryKeyLock = null // for lock

      this.$edgeStack.open(this.addEdit.esId)
      // this.$xStepper.navigate(this.addEdit.esId).to(4)
    },

    onDeleteSucceeded() {
      this.$store.dispatch('fsTable/fetchData')
      this.$notify({
        group: 'bottomRight',
        type: 'success',
        title: 'Vehicle Deleted',
        text: `Vehicle has been deleted successfully.`,
      })
    },

    onDeleteFailed({ data }) {
      console.error('onDeleteFailed', data)
      this.$notify({
        group: 'bottomRight',
        type: 'error',
        title: 'Failed to Delete!',
        text: `${data.detail}`,
      })
    },

    toggleDropdown() {
      this.dropdownPopoverShow = !this.dropdownPopoverShow
    },

    getVehicleType(vehicle_type) {
      const types = {
        P: 'Scooter',
        S: 'Bike',
        E: 'E-Bike',
      }
      return types[vehicle_type] || '--'
    },

    add(type) {
      this.dropdownPopoverShow = false

      // todo: move to parent component?
      if (type === 'single') {
        this.onOpenES({ primaryKey: null })
        return
      }

      this.$emit(`add:${type}`)
    },

    edit(vehicle) {
      this.$emit('edit', { vehicle })
    },

    showFlags(item) {
      let enabled = this.getEnabledFlags(item)

      let aliases = {
        charging_pick: 'Charging Pick',
        maintainance: 'On Maintainance',
        rebalance: 'Rebalance',

        geo_fence_alert: 'Out OF Geofence',
        iot_fault: 'IOT Fault',
        low_battery: 'low Battery',
        missing: 'Missing',

        is_charging: 'On Charging',
        is_parking: 'On Parking',
        is_reserved: 'On Reservation',
        is_on_ride: 'On Ride',
        is_idle: 'Idle',

        none: 'None',
      }

      let res = []
      for (const key in aliases) {
        if (enabled.includes(key)) {
          res.push(aliases[key])
        }
      }
      // console.log({ resss: res });
      // console.log(res.join(", "));
      return res.join(', ')
    },
    getEnabledFlags(item) {
      let flags = {
        ...item.general_flags,
        ...item.operational_flags,
        ...item.status_flags,
      }
      // console.log(flags);
      let enabled = []

      for (const key in flags) {
        console.log('v-flag', key, flags[key])
        if (key !== 'is_idle' && flags[key] === true) {
          enabled.push(key)
        }
      }
      // console.log(enabled);
      return enabled
    },
    getFlagAlias(flag, data = {}) {
      let aliases = {
        charging_pick: 'Charging Pick',
        maintainance: 'On Maintainance',
        rebalance: 'Rebalance',
        swap_battery: 'Swap Battery',

        geo_fence_alert: 'Geofence Alert',
        iot_fault: data.general_flags.fault_status_reason,
        low_battery: 'low Battery',
        missing: 'Missing',

        is_charging: 'On Charging',
        is_parking: 'On Parking',
        is_reserved: 'On Reservation',
        is_on_ride: 'On Ride',
        is_idle: 'Idle',

        none: 'None',
      }
      return flag in aliases ? aliases[flag] : '--'
    },

    showGoogleMapModal(e) {
      // set current popup vehicle data to generate popup subtitle
      this.mapVehicleData = e

      EventBus.$emit('gmap-popup-alt', e)
      this.$modal.show('gmap-popup-alt')
    },

    async generateFilterOptions() {
      let filters = [
        {
          key: 'status_flag',
          type: 'checkbox',
          input: [
            { text: 'On Trip', value: 'on_ride' },
            { text: 'On Reservation', value: 'on_reservation' },
            { text: 'On Parking', value: 'on_parking' },
            { text: 'On Illegaly Parked', value: 'illegally_parking' },
            { text: 'On Charging', value: 'on_charging' },
            { text: 'On Idle', value: 'idle' },
            { text: 'Damaged', value: 'damaged' },
          ],
          title: 'Status Flags',
        },
        {
          key: 'unlocked_by',
          type: 'checkbox',
          input: [
            { text: 'Admin', value: 'A' },
            { text: 'Rider', value: 'R' },
            { text: 'Operator', value: 'O' },
            { text: 'Suspicious', value: 'U' },
          ],
          title: 'Last Unlocked By',
        },

        {
          key: 'general_flag',
          type: 'checkbox',
          input: [
            { text: 'Missing', value: 'missing' },
            { text: 'IoT Fault', value: 'iot_fault' },
            { text: 'GeoFence Alert', value: 'geofence_alert' },
          ],
          title: 'General Flags',
        },

        {
          key: 'ops_flag',
          type: 'checkbox',
          input: [
            { text: 'Rebalance', value: 'rebalance' },
            { text: 'Charging Pick', value: 'charging_pick' },
            { text: 'Maintenance', value: 'maintenance' },
            { text: 'Swap Battery', value: 'swap_battery' },
          ],
          title: 'Operational Flags',
        },

        {
          key: 'active',
          type: 'checkbox',
          input: [
            { text: 'Active', value: 'true' },
            { text: 'Inactive', value: 'false' },
          ],
          title: 'Connectivity',
        },

        {
          key: 'locked',
          type: 'checkbox',
          input: [
            { text: 'Locked', value: 'true' },
            { text: 'Unlocked', value: 'false' },
          ],
          title: 'Lock',
        },

        {
          key: 'power',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 100,
            minQs: 'min_power_level', // query string for min value
            maxQs: 'max_power_level', // query string for max value
          },
          title: 'Battery',
        },
      ]
      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
      // console.warn({ flGen: filterableFleets }, this.filterOptions)
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}

.tooggle-btn {
  cursor: pointer;
}
.tooggle-btn:hover {
  opacity: 0.8;
}
.tooggle-btn:active {
  opacity: 0.8;
}
.item-btn {
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.item-btn:hover {
  background: #f3f3f3;
}
.vehicle-map-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  width: 27px !important;
  height: 27px !important;
  border-radius: 5px;
  background-color: #ff598a;
  cursor: pointer;
  margin-right: 0.5rem;
}
.lock-unlock-dropdown-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 110px;
  height: 20px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  &.locked {
    @apply bg-oGreen;
  }
  &.unlocked {
    @apply bg-oPurple;
  }
  &.inactive {
    background-color: #c7c7c7;
  }
}
</style>
