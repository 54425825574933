export { default as VehicleAddEdit } from './VehicleAddEdit.vue'
export { default as VehicleAddEditStep1 } from './VehicleAddEditStep1'
export { default as VehicleAddEditStep2 } from './VehicleAddEditStep2'

/**
 *
 * @param {Object} args
 * @param {Number} args.step
 * @param {String} args.mode add | edit
 * @returns {object} models
 */
export function getVehicleFormModel({ step, mode = 'add' }) {
  const models = {
    add: {
      // lock
      1: {
        iot_category: '',

        name: '',
        manufacturer: '',
        lock_id: '',
        factory_qr: '',
        iccid: '',
        sim_number: '',

        description: '',
      },

      // vehicle
      2: {
        name: '',
        qr_code: '',
        manufacturer: '',
        bike_category: '',
        fleet: '',
        is_under_simulation: false,
        simulated_new_loc_coord: '',
        description: '',
      },
    },

    edit: {
      1: {
        iot_category: '',

        name: '',
        manufacturer: '',
        lock_id: '',
        factory_qr: '',
        iccid: '',
        sim_number: '',

        description: '',

        firmware_key: '',
        firmware_type: '',
        operator_apn: '',
        start_date: '',
      },

      2: {
        name: '',
        qr_code: '',
        manufacturer: '',
        bike_category: '',
        fleet: '',
        is_under_simulation: false,
        simulated_new_loc_coord: '',
        description: '',
      },
    },
  }

  return models[mode][step]
}
