var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex w-auto mt-2 md:items-center md:justify-end md:mt-0"},[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var mousedownHandler = ref.mousedownHandler;
        var focusHandler = ref.focusHandler;
        var blurHandler = ref.blurHandler;
        var keydownHandler = ref.keydownHandler;
return [_c('button',{class:_vm.getButtonClass,attrs:{"aria-label":"Vehicle Actions","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[(_vm.lockReq)?_c('loading',{attrs:{"height":25,"opacity":0,"color":"#fff","active":true,"loader":"bars","is-full-page":false}}):_vm._e(),(!_vm.lockReq)?_c('span',[_c('i',{staticClass:"mr-2 fas fa-chevron-down"}),_vm._v(" "+_vm._s(_vm.getStatusText)+" ")]):_vm._e()],1)]}},{key:"default",fn:function(ref){
        var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-lg",on:{"click":function($event){return hide()}}},[(_vm.$acl.canUpdate('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":_vm.handleVehicleLockUnlock}},[_vm._v(" "+_vm._s(_vm.status === true ? 'Unlock' : 'Lock')+" ")]):_vm._e(),(_vm.$acl.canUpdate('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":_vm.forceSync}},[_vm._v(" Force Sync Status ")]):_vm._e(),(_vm.$acl.canUpdate('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":_vm.triggerRing}},[_vm._v(" Trigger Ring ")]):_vm._e(),(_vm.$acl.canView('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":_vm.downloadQRCode}},[_vm._v(" Download QRCode ")]):_vm._e(),(_vm.$acl.canUpdate('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":_vm.onEditVehicle}},[_vm._v(" Edit Vehicle ")]):_vm._e(),(_vm.$acl.canDelete('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",attrs:{"variant":"danger"},on:{"click":_vm.askConfirmation}},[_vm._v(" Delete Vehicle ")]):_vm._e()],1)]}}])})],1),_c('ConfirmFirst',{key:("confirmFirst-" + _vm.vehicleId),attrs:{"id":("confirmFirst-" + _vm.vehicleId),"is-loading":_vm.isDeleting,"title":"Delete The Vehicle?","subtitle":"All associated data will be deleted. This action cannot be undone.","criticalityLevel":"medium","criticalityTitle":"Please Type The Delation Code","criticalityPassword":"ILOVEOTORIDE","confirm-button-text":"Delete"},on:{"confirm":_vm.onDeleteConfirm,"cancel":_vm.onDeleteCancel}}),_c('DownloadQRCode',{key:("downloadQRCode-" + _vm.vehicleId),attrs:{"id":("downloadQRCode-" + _vm.vehicleId),"code":_vm.qrCode}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }