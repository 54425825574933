export const SingleScooterConfig = {
  api: {
    // get
    index: '/dashboard/organizations/',
    // get
    single_lock: (id = 'uuid') => {
      return `/dashboard/locks/${id}/`
    },
    single_vehicle: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    // post [add-step-1]
    iot: {
      // assigned & unassigned dropdown
      dropdown: `/dashboard/locks/?dropdown`,
      // unassigned dropdown
      unassigned: `/dashboard/locks/?dropdown&not_assigned`,
    },
    create: '/dashboard/organizations/',
    create_iot: '/dashboard/locks/',
    update_iot: (id = 'uuid') => {
      return `/dashboard/locks/${id}/`
    },

    create_vehicle: '/dashboard/vehicles/',
    update_vehicle: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    fleets: '/dashboard/fleets/',
    // patch [edit|add-step-2]
    update: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    iot_manufacture: '/dashboard/lock-manufacturers/',
    vehicle_manufacture: '/dashboard/vehicle-manufacturers/',
    iot_index: '/dashboard/locks/',
    summary: {},
    profile: {},
    // fleets: {
    //   // todo: merge?
    //   // get
    //   index: "/dashboard/fleets/",
    //   create: ""
    // }
  },
  events: {
    name: 'single-scooter',
    // refresh
    refresh: `refresh-scooter-single`,
    // slideover-right
    sorId: 'scooter-single',
    sorOpen: 'sor-open-scooter-single',
    sorClose: 'sor-close-scooter-single',
    sorToggle: 'sor-toggle-scooter-single',
    // editing-data
    editingData: 'edit-scooter-single-data',
    editingStep1Data: 'edit-scooter-single-step-1-data',
    editingStep2Data: 'edit-scooter-single-step-2-data',

    succeededStep1: 'succeeded-step-1',
    succeededStep2: 'succeeded-step-2',
    //   succeededStep3: "succeeded-step-3",
    //   successedMultiple: "succeeded-multiple",
    // viewing-data
    //viewingData: "view-organization-data"
  },
}
