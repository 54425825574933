<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <section>
        <!-- row-1 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.name"
            type="text"
            name="Vehicle Name"
            label="Vehicle Name"
            rules="required"
            placeholder="e.g. Otoride 101"
          />

          <AppInput
            v-model="form.qr_code"
            type="text"
            name="Vehicle QR Code"
            label="Vehicle QR Code"
            rules="required"
            placeholder="e.g. 1000100001"
          />
        </div>
        <!-- /row-1 -->

        <!-- row-2 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.manufacturer"
            type="richselect"
            name="Vehicle Manufacturer"
            label="Vehicle Manufacturer"
            rules="required"
            placeholder="Select a manufacturer"
            text-attribute="name"
            value-attribute="id"
            :options="vehicleManufacturers"
            hide-search-box
          />

          <AppInput
            v-model="form.bike_category"
            type="richselect"
            name="Vehicle Type"
            label="Vehicle Type"
            rules="required"
            placeholder="Select a type"
            text-attribute="text"
            value-attribute="value"
            :options="vehicleTypes"
            hide-search-box
          />
        </div>
        <!-- /row-2 -->

        <!-- row-3 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.fleet"
            type="richselect"
            name="Assigned Fleet"
            label="Assigned Fleet"
            placeholder="Select a fleet"
            text-attribute="name"
            value-attribute="id"
            :options="fleets"
            hide-search-box
          />

          <AppInput
            :value="getSelectedFleetLocation"
            type="text"
            name="Fleet Location"
            label="Fleet Location"
            placeholder="--"
            disabled
          />
        </div>
        <!-- /row-3 -->

        <div>
          <TCheckbox
            v-model="form.is_under_simulation"
            label="Enable Location Simulation Mode"
            wrapped
            @change="onUseLatLongField"
          />

          <transition name="fade">
            <div
              class="grid grid-cols-2 gap-4"
              ref="latLongFieldRef"
              v-if="form.is_under_simulation"
            >
              <AppInput
                v-model="latitude"
                type="text"
                name="Latitude"
                label="Latitude"
                rules="required"
                placeholder="e.g. 24.4408"
              />

              <AppInput
                v-model="longitude"
                type="text"
                name="Longitude"
                label="Longitude"
                rules="required"
                placeholder="e.g. 90.1204"
              />
            </div>
          </transition>
        </div>

        <div>
          <TCheckbox
            v-model="useDescriptionField"
            label="Add description (optional)"
            wrapped
            @change="onUseDescriptionField"
          />

          <transition name="fade">
            <AppInput
              ref="descriptionFieldRef"
              v-if="useDescriptionField"
              v-model="form.description"
              type="textarea"
              name="Description"
              label="Description"
            />
          </transition>
        </div>
      </section>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects, xMan } from '@/utils'
// import { getFormModel } from '.'

export default {
  name: 'VehicleAddEditStep2',

  components: {},

  props: {
    // vehicle.lock.id (created or selectedLockId)
    primaryKeyLock: {
      required: false,
    },
    fleets: {
      type: Array,
      required: true,
    },
    vehicleTypes: {
      type: Array,
      required: true,
    },
    // todo: remove ths
    selectedLockId: {
      type: String,
      required: true,
    },
    vehicleManufacturers: {
      type: Array,
      required: true,
    },

    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    // const { payment, link, domain } = getFormModel({ step: 3 })
    // console.log(payment, link, domain, 'form3')

    return {
      isLoading: false,

      useDescriptionField: false,

      form: {
        full_name: '',
        username: '',
        email: '',
        phone_number: '',
      },
      latitude: '',
      longitude: '',
      password: undefined,
      passwordConfirmation: undefined,
      // isOrgUserCreated: false,
    }
  },

  computed: {
    isOrgUserCreated() {
      return !!this.orgUserId
    },

    getPasswordValidationRules() {
      if (!this.isOrgUserCreated) {
        return 'required|min:8'
      }

      return this.isEditing ? 'min:8' : 'required|min:8'
    },

    getSelectedFleet() {
      return this.fleets.find((item) => item.id === this.form.fleet)
    },
    getSelectedFleetLocation() {
      return this.getSelectedFleet?.geofence?.name || '--'
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
          const [lat, long] = this.form.simulated_new_loc_coord?.split(',')
          this.latitude = lat
          this.longitude = long
          // this.isOrgUserCreated = !!this.form.username
          console.log(this.form, this.isOrgUserCreated, 'ff4')
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 4, data: this.form })
      },
    },
  },

  methods: {
    async submit() {
      await this.$refs.submitButton.click()
    },

    async submitStep() {
      const url =
        this.isEditing || this.primaryKey
          ? useEndpoints.vehicle.update(this.primaryKey)
          : useEndpoints.vehicle.create()

      const method = this.isEditing || this.primaryKey ? 'PATCH' : 'POST'
      this.form.simulated_new_loc_coord = this.latitude.concat(
        ',',
        this.longitude
      )

      if (!this.form.is_under_simulation) {
        // delete this.form.is_under_simulation
        delete this.form.simulated_new_loc_coord
      }

      const formDataProxy = { ...this.form }

      // add additional required data
      formDataProxy.lock = this.primaryKeyLock

      const data = new xMan(formDataProxy).toFormData()
      console.log('v-step2', formDataProxy, data)
      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          // todo: push to unassignedLocks
          console.log({ save: res.data })
          this.$emit('save', { step: 2, data: res.data })

          const message = `Vehicle ${
            this.isEditing || this.primaryKey ? 'updated' : 'added '
          } successfully`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err.response)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response,
          })
        })
        .finally(() => (this.isLoading = false))
    },
    onUseLatLongField(e) {
      if (e) {
        const i = setTimeout(() => {
          this.$refs.latLongFieldRef.focus()
          clearTimeout(i)
        }, 150)
      }
    },

    onUseDescriptionField(e) {
      if (e) {
        const i = setTimeout(() => {
          this.$refs.descriptionFieldRef.focus()
          clearTimeout(i)
        }, 150)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.section-name {
  @apply text-sm font-bold text-gray-500;
}
</style>
