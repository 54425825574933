<template>
  <div>
    <add-multiple-scooter />
    <add-single-scooter />

    <VehicleIndex
      :fst-id="`fleet-vehicles`"
      :page-title="`Vehicles`"
      :endpoint="endpoint"
      :qso="qso"
      :tableHeaders="tableHeaders"
      :filterItems="filterOptions"
      @add:single="add('single')"
      @add:multiple="add('multiple')"
      @edit="edit"
    />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils/EventBus'
import { MultipleScooterConfig } from '@/config/MultipleScooterConfig'
import { SingleScooterConfig } from '@/config/SingleScooterConfig'
import { VehicleIndex } from '@/composites/vehicle'
export default {
  name: 'ScooterIndex',
  components: {
    AddSingleScooter: () => import('@/views/scooter/AddSingleScooter.vue'),
    AddMultipleScooter: () => import('@/views/scooter/AddMultipleScooter.vue'),
    VehicleIndex,
  },

  data() {
    return {
      is_edit: false,
      qso: { append: '', prepend: '' },
      endpoint: null,
      tableHeaders: [
        { text: 'Name', width: '10%', sort: null },
        { text: 'Battery', width: '10%', sort: 'lock__power_level' },
        { text: 'IMEI', width: '10%', sort: null },
        { text: 'QRCode', width: '10%', sort: null },
        { text: 'Last Connected', width: '13%', sort: null },
        { text: 'Last Located', width: '13%', sort: null },
        { text: 'Flags', width: '12%', sort: null },
        { text: 'Heart Beat', width: '8%', sort: null },
        { text: 'Last Location', width: '9%', sort: null },
        { text: 'Actions', width: '5%', sort: null },
      ],
      filterOptions: [
        {
          key: 'bike_category',
          type: 'checkbox',
          input: [
            { text: 'Scooters', value: 'P' },
            { text: 'EBikes', value: 'E' },
            { text: 'Bikes', value: 'S' },
          ],
          title: 'Category',
        },
        {
          key: 'status_flag',
          type: 'checkbox',
          input: [
            { text: 'On Trip', value: 'on_ride' },
            { text: 'On Reservation', value: 'on_reservation' },
            { text: 'On Parking', value: 'on_parking' },
            { text: 'On Illegaly Parked', value: 'illegally_parking' },
            { text: 'On Charging', value: 'on_charging' },
            { text: 'On Idle', value: 'idle' },
            { text: 'Damaged', value: 'damaged' },
          ],
          title: 'Status Flags',
        },

        {
          key: 'general_flag',
          type: 'checkbox',
          input: [
            { text: 'Missing', value: 'missing' },
            { text: 'IoT Fault', value: 'iot_fault' },
            { text: 'GeoFence Alert', value: 'geofence_alert' },
          ],
          title: 'General Flags',
        },

        {
          key: 'ops_flag',
          type: 'checkbox',
          input: [
            { text: 'Rebalance', value: 'rebalance' },
            { text: 'Charging Pick', value: 'charging_pick' },
            { text: 'Maintenance', value: 'maintenance' },
            { text: 'Swap Battery', value: 'swap_battery' },
          ],
          title: 'Operational Flags',
        },

        {
          key: 'active',
          type: 'checkbox',
          input: [
            { text: 'Active', value: 'true' },
            { text: 'Inactive', value: 'false' },
          ],
          title: 'Connectivity',
        },

        {
          key: 'locked',
          type: 'checkbox',
          input: [
            { text: 'Locked', value: 'true' },
            { text: 'Unlocked', value: 'false' },
          ],
          title: 'Lock',
        },

        {
          key: 'power',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 100,
            minQs: 'min_power_level', // query string for min value
            maxQs: 'max_power_level', // query string for max value
          },
          title: 'Battery',
        },
      ],
    }
  },
  created() {
    this.generateEndPoint()
  },

  methods: {
    generateEndPoint() {
      this.endpoint =
        useEndpoints.vehicle.index() + `?fleet=${this.$route.params.id}&`
    },
    add(type) {
      if (type == 'single') {
        console.log('single = ', SingleScooterConfig.events.sorToggle)
        EventBus.$emit(SingleScooterConfig.events.editingData, {})
        dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
      } else {
        EventBus.$emit(MultipleScooterConfig.events.editingData, {})
        dispatchEvent(new Event(MultipleScooterConfig.events.sorToggle))
      }
      this.is_edit = false
      this.dropdownPopoverShow = false
    },

    edit({ vehicle }) {
      console.log(vehicle)
      this.is_edit = true
      EventBus.$emit(SingleScooterConfig.events.editingData, vehicle)
      dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
    },
  },
}
</script>
